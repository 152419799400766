import React from "react";
import { Link } from "react-router-dom";

const GetInTouch = ({ title }) => (
  <section>
    <div className="navy-bg py-5">
      <div className="container py-4">
        <h2 className="mb-4 pb-4 text-white">{title || "Sign-up for free"}</h2>
        <p className="mb-md-4 mb-lg-5">
          Start your on-boarding and access our API automatically.
        </p>
        <div className="d-flex justify-content-center">
          <Link to="/register">
            <button
              className="btn btn-main mx-2 mx-md-3 mx-lg-4"
              style={{ width: "150px" }}
            >
              Sign Up
            </button>
          </Link>
          <Link to="/company/#contactForm">
            <button
              href="#"
              className="btn btn-second mx-2 mx-md-3 mx-lg-4"
              style={{ width: "150px" }}
            >
              Contact us
            </button>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default GetInTouch;
