import React from "react";
import Benefits from "../components/Benefits/Benefits";
import Hero from "../components/Hero/Hero";
import Lead from "../components/Lead/Lead";
import ProductsCards from "../components/ProductsCards/ProductsCards";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import Explore from "../components/Explore/Explore";
import CaseStudy from "../components/CaseStudy/CaseStudy";
import IndustryNews from "../components/IndustryNews/IndustryNews";

const HomePage = ({ data }) => {
  const { hero, lead, benefits, follow, products, explore } = data;
  return (
    <main>
      <div className="text-center">
        <Hero data={hero} />
        <Lead data={lead} />
        <ProductsCards data={products} />
        <Lead data={follow} />
        <Benefits data={benefits} />
        <GetInTouch />
        <Explore data={explore} />
        <CaseStudy />
        <GetInTouch />
        <IndustryNews />
      </div>
    </main>
  );
};

export default HomePage;
