import React from "react";
import Banner from "../components/Banner/Banner";
import FormSection from "../components/FormSection/FormSection";
import MapSection from "../components/MapSection/MapSection";
import SimpleSection from "../components/SimpleSection/SimpleSection";

const CompanyPage = ({ data }) => {
  const { header, story } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <SimpleSection data={story} />
        <FormSection />
        <MapSection />
      </div>
    </main>
  );
};

export default CompanyPage;
