import React, { Component } from "react";
import { Link } from "react-router-dom";
class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      signingIn: false,
      isSent: "",
    };
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  submit = async (event) => {
    event.preventDefault();
    this.setState({ signingIn: true });
    setTimeout(() => {
      this.setState({
        isSent: "error",
        signingIn: false,
        email: "",
        password: "",
      });
    }, 200);
  };
  render() {
    return (
      <div className="container pb-5 mt-5 text-left">
        <form name="loginForm">
          <div>
            <h3 className="my-5 text-white">Access your dashboard</h3>
          </div>
          <div className="">
            <div className="form-group my-3">
              <label className="lable text-white" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={this.onChange}
                value={this.state.email}
                className="form-control"
              />
            </div>
            <div className="form-group my-5">
              <label className="lable text-white" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={this.onChange}
                value={this.state.password}
                className="form-control"
              />
            </div>
            <div className="text-right my-5 pt-3">
              <button
                className="btn btn-second px-5 w-100"
                onClick={(e) => this.submit(e)}
              >
                Login
              </button>
            </div>
            {/* {this.state.sendingEmail && <Spinner />} */}

            {this.state.isSent === "error" && (
              <div className="text-danger my-4">
                <i className="fas fa-exclamation-triangle"></i> Incorrect email
                or password
              </div>
            )}
          </div>
        </form>
        <div className="text-center">
          Don't have an account?
          <Link className="link text-white-50" to="/Register">
            {" "}
            Sign up
          </Link>
        </div>
      </div>
    );
  }
}

export default LoginForm;
