import React from "react";
import Particle from "../Particle/Particle";
import useWindowDimensions from "../Particle/helper/useWindowDimensions";

const Hero = ({ data }) => {
  const { height, width } = useWindowDimensions();
  const windowHeight = height - 58;
  const particlesHeight = width > 500 ? 750 : windowHeight;
  return (
    <div>
      <div
        style={{ height: particlesHeight + "px", top: 0 }}
        className="d-flex container align-items-center px-lg-5"
      >
        <h1 className="text-white mx-md-5 mx-sm-3 px-lg-5">{data}</h1>
      </div>
      <Particle height={particlesHeight} />
    </div>
  );
};

export default Hero;
