import React from "react";

const BenefitCards = ({ item }) => {
  return (
    <div className="col-md-6 col-lg-4 mt-3 my-md-4">
      <div className="card bg-transparent border-0 mx-0">
        <div className="card-body p-lg-0">
          <div className="card-title text-md-left">
            <img
              className="icon card-img-top"
              src={item.icon}
              alt={item.title}
            />
            <div className="d-md-inline">{item.title}</div>
          </div>
          <p className="card-text text-md-left">{item.text}</p>
        </div>
      </div>
    </div>
  );
};

export default BenefitCards;
