import { Markup } from "interweave";
import React from "react";

const SimpleSection = ({ data }) => {
  const { title, content } = data;
  return (
    <section>
      <div className="light-bg py-5">
        <div className="container py-4">
          {title && <h2 className="mb-5">{title}</h2>}
          <Markup content={content} />
        </div>
      </div>
    </section>
  );
};

export default SimpleSection;
