import React from "react";
import Banner from "../components/Banner/Banner";
import FeaturesSection from "../components/FeaturesSection/FeaturesSection";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import IndustryNews from "../components/IndustryNews/IndustryNews";

const CompliancePage = ({ data }) => {
  const { header, complianceCards } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <FeaturesSection data={complianceCards} />
        <GetInTouch />
        <IndustryNews />
      </div>
    </main>
  );
};

export default CompliancePage;
