import React from "react";
import { Markup } from "interweave";

const Cards = ({ cards, textColor }) => (
  <div className="card-deck">
    {cards.map((item, i) => (
      <div key={i} className="col-lg-6  my-5">
        <div className="card bg-transparent border-0">
          <div className="card-body p-lg-0 text-lg-left">
            <div className="card-title d-lg-flex justify-content-start align-items-center">
              <img
                className="icon card-img-top mb-2 ml-0"
                src={item.icon}
                alt={item.title}
              />
              <div className="text-lg-left">
                <p className={"m-0 " + textColor}>{item.title}</p>
                <p className="m-0 card-subtitle">{item.subtitle}</p>
              </div>
            </div>
            <Markup content={item.text} />
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default Cards;
