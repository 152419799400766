import React, { Component } from "react";

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      EMAIL: "",
      company: "",
      website: "",
      message: "",
      sendingEmail: false,
      isSent: "",
      b_3e0e523ab410cdfffa726bf8e_8b10cb890a: "",
    };
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div className="container pb-4 text-left">
        <form
          className="d-lg-flex justify-content-around"
          action="https://finblocks.us19.list-manage.com/subscribe/post?u=3e0e523ab410cdfffa726bf8e&amp;id=8b10cb890a"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          noValidate
        >
          <div className="col-12 col-lg-5">
            <div className="form-group mt-3">
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_3e0e523ab410cdfffa726bf8e_8b10cb890a"
                  tabIndex="-1"
                  value={this.state.b_3e0e523ab410cdfffa726bf8e_8b10cb890a}
                  onChange={this.onChange}
                />
              </div>
              <label htmlFor="contactFirstName">
                First Name <span className="required">*</span>
              </label>
              <input
                type="text"
                id="contactFirstName"
                name="firstName"
                onChange={this.onChange}
                value={this.state.firstName}
                className="form-control"
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="contactLastName">
                Last Name <span className="required">*</span>
              </label>
              <input
                type="text"
                id="contactLastName"
                name="lastName"
                onChange={this.onChange}
                value={this.state.lastName}
                className="form-control"
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="contactEmail">
                Email <span className="required">*</span>
              </label>
              <input
                type="email"
                id="contactEmail mce-EMAIL"
                name="EMAIL"
                onChange={this.onChange}
                value={this.state.email}
                className="form-control"
                required
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="contactCompany">Company</label>
              <input
                type="text"
                id="contactCompany"
                name="company"
                onChange={this.onChange}
                value={this.state.company}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="form-group mt-3">
              <label htmlFor="contactWebsite">Website</label>
              <input
                type="text"
                id="contactWebsite"
                name="website"
                onChange={this.onChange}
                value={this.state.website}
                className="form-control"
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="contactMessage">
                Message <span className="required">*</span>
              </label>
              <textarea
                rows={5}
                id="contactMessage"
                name="message"
                onChange={this.onChange}
                value={this.state.message}
                className="form-control"
                required
              />
            </div>
            <div className="text-right mt-4 pt-3">
              <button className="btn btn-transparent px-5">Submit</button>
              {/* {this.state.sendingEmail && <Spinner />} */}
              {this.state.isSent === "sent" && (
                <div className="text-white mt-4">
                  <i className="fa fa-check mx-2" />
                  Thanks for your inquery. A member of our team will be in touch
                  with you.
                  <br />
                </div>
              )}
              {this.state.isSent === "error" && (
                <div className="text-white mt-4">
                  Error boy, Please try again
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
