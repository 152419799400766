import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import data from "./websiteData";
import MainNavigation from "./components/MainNav/MainNav";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer/Footer";
import BankingPage from "./pages/BankingAsAService";
import HowItworks from "./pages/HowItWorks";
import FeaturesPage from "./pages/FeaturesPage";
import CompliancePage from "./pages/CompliancePage";
import TechnologyPage from "./pages/TechnologyPage";
import CompanyPage from "./pages/CompanyPage";
import TermConditionsPage from "./pages/TermdConditions";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";

class App extends Component {
  constructor() {
    super();
    this.state = { data };
  }

  render() {
    const {
      navbar,
      footer,
      homePage,
      bankingPage,
      workingPage,
      featuresPage,
      compliancePage,
      technologyPage,
      companyPage,
    } = data;
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          <MainNavigation navbar={navbar} />
          <Switch>
            <Route path="/" render={() => <HomePage data={homePage} />} exact />
            <Route
              path="/banking-as-a-service"
              render={() => <BankingPage data={bankingPage} />}
            />
            <Route
              path="/how-it-works"
              render={() => <HowItworks data={workingPage} />}
            />
            <Route
              path="/features"
              render={() => <FeaturesPage data={featuresPage} />}
            />
            <Route
              path="/compliance"
              render={() => <CompliancePage data={compliancePage} />}
            />
            <Route
              path="/technology"
              render={() => <TechnologyPage data={technologyPage} />}
            />
            <Route
              path="/company"
              render={() => <CompanyPage data={companyPage} />}
            />
            <Route
              path="/terms-and-conditions"
              render={() => <TermConditionsPage />}
            />
            <Route
              path="/privacy-policy"
              render={() => <PrivacyPolicyPage />}
            />
            <Route path="/register" render={() => <RegisterPage />} />
            <Route path="/login" render={() => <LoginPage />} />
          </Switch>
          <Footer data={footer} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
