import React from "react";

const ProductsCards = ({ data }) => {
  const { title, cards, regulations } = data;
  return (
    <section>
      <div className="light-bg py-5">
        <div className="container py-4 ">
          <h2 className="mb-5 pb-4 pb-sm-0">{title}</h2>
          <div className="card-deck justify-content-center">
            {cards.map((card, i) => (
              <div className="col-md-6 col-lg-4 my-4 px-0" key={i}>
                <div className="card h-100">
                  <div>
                    <img
                      className="card-img-top w-100"
                      src={card.image}
                      alt={card.title}
                    ></img>
                  </div>

                  <div className="card-body">
                    <div className="card-title text-md-left">{card.title}</div>
                    <p className="card-text text-md-left">{card.text}</p>
                  </div>
                  <div className="card-footer bg-white text-center text-md-left border-0">
                    <form action={card.link}>
                      <button className="card-link">More info</button>
                    </form>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-none">
            <h3 className="my-4">{regulations.title}</h3>
            <p>{regulations.text1}</p>
            <p className="mb-0">{regulations.text2}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsCards;
