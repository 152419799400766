import React from "react";
import LoginForm from "../components/LoginForm/LoginForm";

const LoginPage = () => {
  return (
    <main>
      <div className="light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="blue-bg px-3 rounded my-5">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
