import { Markup } from "interweave";
import React, { Component } from "react";

class IndustryNews extends Component {
  constructor() {
    super();
    this.state = { isLoading: true, articles: [] };
  }

  componentDidMount() {
    this.getArticles();
  }

  getArticles = () => {
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@finblocks"
    )
      .then((res) => res.json())
      .then((data) => {
        // Fillter the array
        const res = data.items; //This is an array with the content. No feed, no info about author etc..
        const cards = res.filter((item) => item.categories.length > 0); // That's the main trick* !
        cards.forEach((card) => {
          // Remove the img in the beginnig of the content
          const contentStart = card.description.indexOf("<p>");
          // not include half a word
          const trimmedText = card.description.slice(
            contentStart,
            contentStart + 100
          );
          // show the first 100 characters (full)
          const retrimmedText = trimmedText.slice(
            0,
            trimmedText.lastIndexOf(" ")
          );
          card.description = retrimmedText;
        });
        this.setState({ cards, isLoading: false });
      });
  };
  render() {
    const { cards, isLoading } = this.state;
    return (
      <section>
        <div className="blue-bg py-5">
          <div className="container py-4">
            <h2 className="mb-5 pb-4 pb-sm-0 text-white">Industry News</h2>
            <div className="card-deck justify-content-center">
              {!isLoading &&
                cards.map((card, i) => (
                  <div className="col-md-6 col-lg-4 my-4 px-0" key={i}>
                    <div className="card h-100 border-0">
                      <div>
                        <img
                          className="card-img-top w-100"
                          src={card.thumbnail}
                          alt={card.title}
                        ></img>
                      </div>

                      <div className="card-body text-dark">
                        <div className="card-title text-md-left">
                          {card.title}
                        </div>
                        <div className="card-text text-md-left">
                          <Markup content={card.description + " ...</p>"} />
                        </div>
                      </div>
                      <div className="card-footer bg-white text-center text-md-left border-0">
                        <form action={card.link} target="_blank">
                          <button
                            className="card-link"
                            href={card.link}
                            target="_blank"
                          >
                            Read Article
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <form
              className="mt-4 pt-5"
              action="https://medium.com/@FinBlocks"
              target="_blank"
            >
              <button className="btn btn-transparent">All Articles</button>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default IndustryNews;
