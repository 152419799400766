import React from "react";
import Banner from "../components/Banner/Banner";
import SimpleSection from "../components/SimpleSection/SimpleSection";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import IndustryNews from "../components/IndustryNews/IndustryNews";

const TechnologyPage = ({ data }) => {
  const { header, technology } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <SimpleSection data={technology} />
        <GetInTouch title="Sign-up to access our API" />
        <IndustryNews />
      </div>
    </main>
  );
};

export default TechnologyPage;
