import React from "react";

const CaseStudy = () => (
  <section>
    <div className="blue-bg py-5">
      <div className="container py-4">
        <img
          className="casestudy-logo mb-3"
          src="/images/logos/crowd2fund.png"
          alt="Crowd2Fund"
        />
        <h2 className="my-4 text-white">Case Study</h2>
        <p className="mb-1">
          Crowd2Fund is a state of the art mobile investment platform that
          facilitates online investment into entrepreneurs, from a community of
          more than 12,000 investors. Crowd2Fund manages a volume of many
          millions of pounds in unique transactions per month across thousands
          of accounts and multiple user types. They use FinBlocks because it
          offers state of the art technology that by far outperforms any
          competitor systems, and appreciate the enhanced security and
          compliance services offered by FinBlocks.
        </p>
      </div>
    </div>
  </section>
);

export default CaseStudy;
