import React, { Component } from "react";
import Carousel from "nuka-carousel";
import BenefitCards from "../Benefits/BenefitCards";

class MyCarousel extends Component {
  state = {
    slideIndex: 0,
  };

  render() {
    return (
      <Carousel
        slideIndex={this.state.slideIndex}
        afterSlide={(slideIndex) => {
          slideIndex === 5
            ? setTimeout(() => {
                this.setState({ slideIndex: 0 });
              }, 2000)
            : this.setState({ slideIndex });
        }}
        autoplay={true}
        autoplayInterval={2000}
        className="container mb-3 d-md-none"
        getControlsContainerStyles={(key) => {
          switch (key) {
            case "CenterLeft":
            case "CenterRight":
              return {
                display: "none",
              };
            default:
              return {
                bottom: "-50px",
              };
          }
        }}
      >
        {this.props.data.map((card, i) => (
          <BenefitCards item={card} key={i} />
        ))}
      </Carousel>
    );
  }
}

export default MyCarousel;
