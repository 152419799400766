import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./MainNav.css";
const MainNav = ({ navbar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submenuIsOpen, setSubmenuIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    setSubmenuIsOpen(false);
  };
  const toggleSubmenu = () => setSubmenuIsOpen(!submenuIsOpen);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow">
        <div className="container">
          <Link className="navbar-brand p-0" to="/">
            <img
              src="/images/logos/finblocks-logo.png"
              alt="finblocks"
              className="nav-logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            aria-label="Toggle navigation"
            type="button"
            data-toggle="collapse"
            aria-expanded="false"
            onClick={toggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`navbar-collapse justify-content-lg-between pt-1 pt-xl-0 ${
              isOpen ? "" : "collapse"
            }`}
          >
            <ul className="navbar-nav ml-lg-5 pl-lg-4">
              <hr className="m-0 mt-3 d-lg-none" />
              {navbar.map((item, i) =>
                item.subMenu ? (
                  <React.Fragment key={i}>
                    <li className="nav-item mr-3 my-2 my-lg-0">
                      <div
                        className="d-flex justify-content-between"
                        onClick={toggleSubmenu}
                        onMouseEnter={() => setSubmenuIsOpen(true)}
                        // onMouseLeave={() => setSubmenuIsOpen(false)}
                      >
                        <p className="nav-link m-0">{item.name}</p>
                        <p className="d-lg-none toggle-expand">
                          {submenuIsOpen ? "-" : "+"}
                        </p>
                      </div>

                      <div
                        className={
                          submenuIsOpen ? "dropdown-menu show" : "d-none"
                        }
                        onMouseLeave={() => setSubmenuIsOpen(false)}
                      >
                        {item.subMenu.map((subItem, j) => (
                          <Link
                            className="dropdown-item my-4 my-lg-0"
                            key={j}
                            to={subItem.link}
                            onClick={toggle}
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    </li>
                    <hr className="m-0 d-lg-none" />
                  </React.Fragment>
                ) : (
                  <React.Fragment key={i}>
                    <li className="nav-item mr-3 my-2 my-lg-0" onClick={toggle}>
                      <Link className="nav-link" to={item.link}>
                        {item.name}
                      </Link>
                    </li>
                    <hr className="m-0 d-lg-none" />
                  </React.Fragment>
                )
              )}
            </ul>
            <div className="d-flex flex-column flex-lg-row" onClick={toggle}>
              <Link to="/login">
                <button className="btn btn-second nav-button mr-lg-2 px-4 my-2 my-lg-0 w-100">
                  Log in
                </button>
              </Link>
              <Link to="/register">
                <button className="btn btn-main nav-button ml-lg-2 px-4 my-2 my-lg-0 w-100">
                  Sign up
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MainNav;
