import React from "react";
import Banner from "../components/Banner/Banner";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import Illustration from "../components/Illustration/Illustration";
import SimpleSection from "../components/SimpleSection/SimpleSection";

const HowItworks = ({ data }) => {
  const { header, onboarding, processCards } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <SimpleSection data={onboarding} />
        <Illustration data={processCards} />
        <GetInTouch />
      </div>
    </main>
  );
};

export default HowItworks;
