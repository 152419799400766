import React, { Component } from "react";
import { Link } from "react-router-dom";
class RegisterForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      fullName: "",
      password: "",
      confirmPassword: "",
      consent: true,
      sendingEmail: false,
      isSent: "",
    };
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  submit = async (event) => {
    event.preventDefault();
    this.setState({ sendingEmail: true });
    const requestBody = this.state;
    console.log(requestBody);
  };
  render() {
    return (
      <div className="container py-5 ml-md-3 text-left">
        <form name="RegisterForm">
          <div>
            Sorry, we’re not currently accepting new clients. Please complete
            the
            <Link className="link" to="/company">
              {" contact us "}
            </Link>
            form for more information.
          </div>
          <div className="">
            <div className="form-group my-3">
              <label className="lable" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={this.onChange}
                value={this.state.email}
                className="form-control"
                disabled
              />
            </div>
            <div className="form-group my-5">
              <label className="lable" htmlFor="fullName">
                Full Name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                onChange={this.onChange}
                value={this.state.fullName}
                className="form-control"
                disabled
              />
            </div>
            <div className="form-group my-5">
              <label className="lable" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={this.onChange}
                value={this.state.password}
                className="form-control"
                disabled
              />
            </div>
            <div className="form-group my-5">
              <label className="lable" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={this.onChange}
                value={this.state.confirmPassword}
                className="form-control"
                disabled
              />
            </div>
            <div className="my-5">
              <input
                type="checkbox"
                id="consent"
                name="consent"
                onChange={this.onChange}
                value={this.state.consent}
                className="mr-1"
                disabled
              />
              <label className="small d-inline" htmlFor="consent">
                Don't email me about product updates. If this box is left
                unticked, FinBlocks will occasionally send helpful and relevant
                emails. You can unsubscribe at any time.
                <Link to="/privacy-policy" className="link">
                  {" "}
                  Privacy Policy
                </Link>
              </label>
            </div>
            <div className="text-right my-5 pt-3">
              <button
                className="btn btn-main px-5 w-100"
                onClick={(e) => this.submit(e)}
                disabled
              >
                Create Account
              </button>
              {/* {this.state.sendingEmail && <Spinner />} */}
              {this.state.isSent === "sent" && (
                <div className="text-white mt-4">
                  <i className="fa fa-check mx-2" />
                  Your message was sent, thank you!
                  <br />
                </div>
              )}
              {this.state.isSent === "error" && (
                <div className="text-white mt-4">
                  Error boy, Please try again
                </div>
              )}
            </div>
          </div>
        </form>
        <div className="text-center">
          Have an account?
          <Link className="link" to="/login">
            {" "}
            Log in
          </Link>
        </div>
      </div>
    );
  }
}

export default RegisterForm;
