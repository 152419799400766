import React from "react";
import Cards from "../Cards/Cards";

const Illustration = ({ data }) => {
  const { cards } = data;
  return (
    <section>
      <div className="blue-bg py-5">
        <div className="container py-0 py-lg-5">
          <div className="d-none d-lg-block">
            <img
              className="w-100"
              src="images/onboarding-process.png"
              alt="Onboarding process"
            />
          </div>
          <div className="d-lg-none">
            <Cards cards={cards} textColor="text-white" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Illustration;
