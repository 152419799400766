import React from "react";
import ContactForm from "../ContactForm/ContactForm";

const FormSection = () => {
  return (
    <section id="contactForm">
      <div className="blue-bg py-5">
        <div className="container py-4 mb-5">
          <h2 className="mb-5 text-white">Get in touch</h2>
          <p>
            Use the form below to contact us and send us a message. Our team
            will be in touch within a few hours with a response.
          </p>
        </div>
        <ContactForm />
      </div>
    </section>
  );
};

export default FormSection;
