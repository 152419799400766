import React from "react";
import "./Featurette.css";
import { Markup } from "interweave";
import { Link } from "react-router-dom";

const Featurette = ({ data, index }) => {
  const photoOrder = index % 2 === 0 ? 1 : 2;
  const textOrder = index % 2 !== 0 ? 1 : 2;
  return (
    <div className="row featurette justify-content-around" id={data.id}>
      <div className={`col-lg-6 order-lg-${photoOrder}`}>
        <img className="w-100 mt-4" src={data.image} alt={data.title} />
      </div>
      <div className={`col-lg-6 order-lg-${textOrder}`}>
        <div className="text-lg-left">
          <h3 className="my-3 my-lg-4">{data.title}</h3>
          <Markup content={data.text} />
          <Link className="card-link" to={data.link}>
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Featurette;
