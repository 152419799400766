import React from "react";
import RegisterForm from "../components/RegisterForm/RegisterForm";

const RegisterPage = () => {
  return (
    <main>
      <div className="light-bg">
        <div className="container">
          <div className="row">
            <div className="d-none d-md-flex col-md-5 border-right">
              <div className="py-5">
                <ul className="marketing">
                  <li>
                    <i className="fas fa-check-circle"></i>
                    Create bank accounts and virtual wallets automatically
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Accept multiple
                    payment types across your platform
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Adopt banking as a
                    service to automate your business
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7 bg-white">
              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RegisterPage;
