import React from "react";
import MyCarousel from "../Carousel/Carousel";
import BenefitCards from "./BenefitCards";

const Benefits = ({ data }) => {
  const { title, cards } = data;
  return (
    <section>
      <div className="light-bg py-5 mb-md-0">
        <div className="pb-4">
          <div className="container py-0 py-md-4">
            <h2 className="mb-0 mb-md-4 pb-md-3">{title}</h2>
            <MyCarousel data={cards} />
            <div className="d-none d-md-block">
              <div className="card-deck">
                {cards.map((card, i) => (
                  <BenefitCards item={card} key={i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
