import React from "react";
import Cards from "../Cards/Cards";

const FeaturesSection = ({ data }) => {
  const { title, cards } = data;
  return (
    <section>
      <div className="light-bg py-5">
        <div className="container py-4">
          <h2 className="mb-5">{title}</h2>
          <Cards cards={cards} />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
