import React from "react";

const Footer = ({ data }) => {
  const { logo, menus, socialNetwork } = data;
  return (
    <footer className="border-top">
      <div className="bg-white py-5">
        <div className="container py-4 d-lg-flex">
          <div className="col-lg-3 px-0">
            <img src={logo} alt="finblocks" className="nav-logo greyscale" />
          </div>
          <div className="col-12 col-lg-9 px-0 mt-4 mt-lg-0">
            <div className="d-flex flex-wrap justify-content-between">
              {menus.map((menu, i) => (
                <div key={i} className="col-6 col-lg-3 pb-5 pb-lg-4 pl-0">
                  <h5 className="card-title text-left">{menu.title}</h5>
                  {menu.items.map((item, j) => (
                    <p className="footer-text" key={j}>
                      <a href={item.link}>{item.text}</a>
                    </p>
                  ))}
                </div>
              ))}
              <div className="d-flex justify-content-between col-lg-3 flex-lg-column pb-lg-4">
                <h5 className="card-title text-left d-none d-lg-block">
                  {socialNetwork.title}
                </h5>
                {socialNetwork.items.map((item, j) => (
                  <div className="footer-text" key={j}>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-none d-md-block">
                        <i className={item.icon + " mr-1"}></i>
                        {item.text}
                      </div>
                      <div className="d-md-none">
                        <i className={item.icon + " lead"}></i>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container footer-text">
          <div className="d-flex flex-wrap text-center text-md-left">
            <p className="col-12 col-md-6 px-0">
              © 2020 FinBlocks, All Rights Reserved
            </p>
          </div>
          <p className="col-12 mt-4 px-0">
            FinBlocks Limited (11269670) is registered in England and Wales at
            28 Ely Place, 3rd Floor, London, United Kingdom, EC1N 6TD.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
