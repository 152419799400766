import React from "react";

const MapSection = () => (
  <section>
    <div>
      <iframe
        title="FinBlocks location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2363.789665652251!2d-0.10936181878645432!3d51.51858833338708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b4d951ef3ef%3A0x70de84956344e04!2s28%20Ely%20Pl%2C%20Holborn%2C%20London%20EC1N%206AA!5e0!3m2!1sen!2suk!4v1602775532198!5m2!1sen!2suk"
        width="100%"
        height="450"
        frameBorder="0"
      ></iframe>
    </div>
  </section>
);
export default MapSection;
