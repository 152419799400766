import React from "react";
import Featurette from "../Featurette/Featurette";

const Explore = ({ data }) => {
  const { title, contents } = data;
  return (
    <section>
      <div className="light-bg py-5">
        <div className="container py-4">
          <h2 className="mb-5">{title}</h2>
          {contents.map((content, i) => {
            return <Featurette key={i} data={content} index={i} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Explore;
