let data = {
  navbar: [
    {
      name: "Services",
      subMenu: [
        { name: "Banking as a Service", link: "/banking-as-a-service" },
        { name: "How it Works", link: "/how-it-works" },
        { name: "Features", link: "/features" },
        { name: "Compliance", link: "/compliance" },
      ],
    },
    {
      name: "Technology",
      link: "/technology",
    },
    {
      name: "Company",
      link: "/company",
    },
  ],
  footer: {
    logo: "/images/logos/finblocks-logo.png",
    menus: [
      {
        title: "Services",
        items: [
          {
            text: "Banking",
            link: "/banking-as-a-service",
          },
          {
            text: "How It Works",
            link: "/how-it-works",
          },
          {
            text: "Features",
            link: "/features",
          },
          {
            text: "Compliance",
            link: "/compliance",
          },
        ],
      },
      {
        title: "Company",
        items: [
          {
            text: "About Us",
            link: "/company",
          },
          {
            text: "Technology",
            link: "/technology",
          },
          {
            text: "Terms & Conditions",
            link: "/terms-and-conditions",
          },
          {
            text: "Privacy Policy",
            link: "/privacy-policy",
          },
        ],
      },
      // {
      //   title: "Resource",
      //   items: [
      //     {
      //       text: "Support",
      //       link: "/",
      //     },
      //     {
      //       text: "Industry News",
      //       link: "/",
      //     },
      //     {
      //       text: "Case Studies",
      //       link: "/",
      //     },
      //     {
      //       text: "Explore FinBlocks",
      //       link: "/",
      //     },
      //   ],
      // },
    ],
    socialNetwork: {
      title: "Connect",
      items: [
        {
          text: "Facebook",
          link: "https://www.facebook.com/FinBlocs/",
          icon: "fab fa-facebook",
        },
        {
          text: "Twitter",
          link: "https://twitter.com/BlocksFin",
          icon: "fab fa-twitter",
        },
        {
          text: "linkedIn",
          link: "https://www.linkedin.com/showcase/finblocks/",
          icon: "fab fa-linkedin",
        },
        {
          text: "Medium",
          link: "https://medium.com/@FinBlocks/",
          icon: "fab fa-medium",
        },
      ],
    },
  },
  homePage: {
    hero: "Financial technology for a new era of finance",
    lead:
      "<p>FinBlocks is a state of the art financial platform for innovative businesses.</p><p> We provide secure deposits and payment services automatically for your business via an application interface, fully supported by our dedicated team.</p>",
    products: {
      title: "Revolutionary software",
      cards: [
        {
          title: "Deposits and payments",
          text:
            "Create secure accounts for your users and hold client funds safely.",
          image: "images/secure-deposits.jpg",
          link: "#wallets",
        },
        {
          title: "Open banking API",
          text:
            "Integrate your platform seamlessly and easily through our high performance API.",
          image: "images/open-banking-api.jpg",
          link: "#automation",
        },
        {
          title: "Technology compliance",
          text:
            "Utilise the latest state of the art technology to protect your business.",
          image: "images/technology-compliance.jpg",
          link: "/compliance",
        },
      ],
      regulations: {
        title: "FCA Regulated",
        text1:
          "FinBlocks is fully regulated by the Financial Conduct Authority.",
        text2: "All systems and processes strictly adhere to FCA guidelines.",
      },
    },
    follow:
      "We are a leading technology partner for forward thinking businesses, supporting your success in an increasingly competitive global marketplace. FinBlocks was built to help you focus on your customers, maximise your operational efficiency, and reduce risk.",

    benefits: {
      title: "Boost your platform",
      cards: [
        {
          title: "Reduce cost",
          text: "Automate your transactions and keep your clients money safe.",
          icon: "./images/icons/reduce-cost.png",
        },
        {
          title: "Reduced risk",
          text: "Reduce your risk through better technology and compliance.",
          icon: "./images/icons/reduce-risk.png",
        },
        {
          title: "Higher revenue",
          text: "Generate higher revenue by utilising automation.",
          icon: "./images/icons/higher-revenue.png",
        },
        {
          title: "Quicker to market",
          text: "Focus on your business while we look after your back end.",
          icon: "./images/icons/quicker-to-market.png",
        },
        {
          title: "Outsourced expertise",
          text: "Gain an operational edge by using our software as a service.",
          icon: "./images/icons/outsourced-expertise.png",
        },
        {
          title: "Unique to you",
          text: "Customise the platform to meet your business needs.",
          icon: "./images/icons/unique-to-you.png",
        },
      ],
    },
    explore: {
      title: "Explore FinBlocks",
      contents: [
        {
          title: "Automate with open banking",
          text:
            "<p>Our open banking service works through our well documented and easy to integrate API, so you can securely and automatically manage your users accounts.</p><p> FinBlocks gives your business the full banking capability it needs, in a much friendlier and more flexible way than outdated legacy systems. Technology is at the core of what we do; our goal is to help you scale your financial capability  through smart services and automation, allowing you to grow your business much more effectively.</p>",
          image: "images/open-banking.png",
          link: "/banking-as-a-service",
        },
        {
          title: "Users bank accounts and wallets",
          text:
            "Virtual wallets are a dynamic and secure way to hold your clients funds. These funds are stored in a segregated client bank account; an event sourced transaction ledger that can not be tampered with is an absolute record of all transactions and balances.  Wallets are similar to a bank account, and can be set-up instantly via the API and they also have their own account number and sort code",
          image: "images/users-bank-accounts-and-wallets.png",
          id: "wallets",
          link: "/banking-as-a-service",
        },
        {
          title: "Quick and seamless integration",
          text:
            "<p>Manually processing client money and payments robs your business of time better spent elsewhere.  Automate your client money and payment processes using our  state of the art system, offering seamless integration with your existing platform.</p><p> All functions such as creating an account, adding or withdrawing funds, or scheduling payments is possible using this interface.</p><p> The FinBlocks service is complimented by the client dashboard, where your operational team can manage and monitor transaction activity effectively and with full transparency.</p>",
          image: "images/automation.png",
          id: "automation",
          link: "/how-it-works",
        },
        {
          title: "A stronger product through excellent compliance",
          text:
            "We are a fully and directly regulated Electronic Money Issuer;  you can be reassured that all our systems and processes are in line with our FCA regulatory obligations. We treat regulation and compliance with the utmost seriousness, and have since day one.",
          image: "images/fca-regulation.png",
          link: "/compliance",
        },
        {
          title: "Enhanced security giving you additional protection",
          text:
            "<p>Security and performance are critical requirements for any transactional system. </p><p>FinBlocks architecture uses revolutionary engineering methodologies to deliver world-leading security and performance practices. We are tested by external experts on a regular basis to ensure our system is not penetrable from hostile actors, and that there we have the capacity to handle high volumes of transactions when needed.</p>",
          image: "images/secure-and-high-performance.png",
          link: "/features",
        },
        {
          title: "Immaculate technology for your business",
          text:
            "<p>Excellence is our only option. Our system has been designed and built to guarantee the quality of our product and services is only of the highest standard.</p><p> Our system architecture uses event sourcing to manage your transaction ledger, making it secure and tamper-proof. Instead of one encryption key for the entire system, every single user has their own unique key, creating superior security and an almost impenetrable deposit capability. </p>",
          image: "images/technology.png",
          link: "/technology",
        },
      ],
    },
  },
  bankingPage: {
    header: {
      title: "Fully automate your business banking needs",
      text1:
        "Banking as a service is a new financial service capability available due to regulatory reform and technology.",
      text2:
        "Our digital banking services are available on demand, and these services are delivered automatically in real time, via a secure application interface or API. It is an end-to-end process delivering automated banking and payment services for your business. This offers many benefits for business operations, such as significantly lower overheads, reduced risk, better compliance and higher security.",
      banner: "/images/banners/banking-as-a-service.png",
    },
    whyUs: {
      title: "Choose innovation",
      content:
        "<p>We are undergoing a financial revolution, with decentralisation and automation of banking services. FinBlocks outperforms other providers through delivering superior technology, focusing on higher performance and automation in an incredibly cost effective way. </p><p>Operational efficiency is paramount in a competitive world. FinBlocks is a banking as a service platform, delivering all the tasks of your normal bank, but via an API. This allows you to automate many of your internal processes, reduce risk and increase compliance. </p><p>Read on to explore all the functions our platform performs for you.</p>",
    },
    benefits: {
      title: "Benefits to your business",
      cards: [
        {
          title: "Reduce cost",
          text:
            "<p>Requiring teams of people to reconcile and monitor payments is simply outdated.</p><p>FinBlocks allows you to fully automate your transactions and client fund management. You don’t just save costs through automation — automating your payments also reduces errors and fraud. With FinBlocks,  you can also rest assured that all your regulatory obligations will be met, providing additional cost savings.</p>",
          icon: "./images/icons/reduce-cost.png",
        },
        {
          title: "Reduced risk",
          text:
            "<p>Holding client money securely is a huge risk and responsibility. We pride ourselves on looking after your clients money as if it was our own. With increasing cyber threats and crime, FinBlocks adopts only the best operational practices to ensure that your business and customers are safe. Automating these processes with state of the art technology reduces further risk associated with human error. We also employ the strictest monitoring capabilities to flag potential risks as soon as possible.</p>",
          icon: "./images/icons/reduce-risk.png",
        },
        {
          title: "Higher revenue",
          text:
            "<p>Partnering with FinBlocks enables you to focus on your customer,  rather than worrying about payments, security of client money and compliance. Our technology will also allow you to provide a higher performing service to your clients; coupled with lower operating overheads, this unlocks more money to invest in your client experience.</p>",
          icon: "./images/icons/higher-revenue.png",
        },
        {
          title: "Quicker to market",
          text:
            "<p>Whether you are an established business, or building a new product or service, integration with FinBlocks is quick and hassle-free. Our client onboarding process is painless, and we take the effort required to hold your client money and manage your payments out of your equation. We’re an agile plug and play solution. If you’re migrating existing data and moving away from a legacy provider, our experienced team will manage this for you.</p>",
          icon: "./images/icons/quicker-to-market.png",
        },
      ],
    },
  },
  workingPage: {
    header: {
      title: "Working with us will help future proof your business",
      text1:
        "We integrate with your existing platform easily via a well documented and secure application interface, or API.",
      text2:
        "It’s easy to automate and outsource your client accounts, payments and compliance services with FinBlocks. There are a number of banking services which are triggered from your platform; our system then performs the banking tasks you would normally do manually, automatically.",
      banner: "/images/banners/how-it-works.png",
    },
    onboarding: {
      title: "Easy on-boarding process",
      content:
        "Choosing a technology partner should not be taken lightly, and time is needed to conduct thorough due diligence on both sides. When a clear fit between your business and FinBlocks has been determined, the simple onboarding process starts. We will keep it as simple as possible for you, through our thorough understanding of the process of setting you up on the platform, and dealing with any potential complexities when migrating onto the platform - all supported by our dedicated team.",
    },
    processCards: {
      cards: [
        {
          title: "Initial due diligence",
          text:
            "After signing up to the platform you will be required to provide some basic documentation to allow us to meet our regulatory obligations. This is all managed seamlessly through your personalised dashboard.",
          icon: "images/icons/our-due-diligence.png",
        },
        {
          title: "Meet your team",
          text:
            "After creating your account you are provided access to our well documented API where your team can take time to understand our architecture and prepare your system for integration.",
          icon: "images/icons/onboarding-meeting.png",
        },
        {
          title: "Understand our API",
          text:
            "After creating your account you are provided access to our well documented API where your team can take time to understand our architecture and prepare your system for integration.",
          icon: "images/icons/requirement-gathering.png",
        },
        {
          title: "Sandbox and profile configuration",
          text:
            "Once initial onboarding is complete, your technology teams will be granted access to our sandbox, test environment, allowing your engineers to experiment with the platform before going live.",
          icon: "images/icons/sandbox-and-profile-configurattion.png",
        },
        {
          title: "Migrating to FinBlocks",
          text:
            "If you have an existing provider offering similar services, we understand the challenges of utilising a new provider. Our proven methodology, quality assurance approach and pre-existing migration and test scrips will make this a seamless transition for you.",
          icon: "images/icons/migrating-to-finblocks.png",
        },
        {
          title: "Switching over and going live",
          text:
            "We offer support and help you manage any down time or potential operational challenges during your go-live, or switchover. We then conduct in-depth testing to ensure all systems are fully integrated and operational.            ",
          icon: "images/icons/switching-over-and-going-live.png",
        },
        {
          title: "Your operational dashboard",
          text:
            "Once you have been successfully onboarded as a client, the FinBlocks team will set up communication, governance and reporting channels. This is reviewed on a regular basis to ensure the whole process is as smooth as possible.",
          icon: "images/icons/operations-and-reporting.png",
        },
        {
          title: "Security and performance testing",
          text:
            "We conduct stress testing and security testing across both systems to validate the robustness of the integration and partnership, ensuring a successful fit between FinBlocks and your business.",
          icon: "images/icons/security-performance-testing.png",
        },
      ],
    },
  },
  featuresPage: {
    header: {
      title: "Immaculately designed with your business in mind",
      text1:
        "FinBlocks is your partner for all your corporate payment and banking services, offering a rich feature set.",
      text2:
        "Every business has a specific set of requirements, and we have built our system to offer everything you need. Our team will work with you to identify your unique requirements, and deliver them through our fully integrated API and custom support services.",
      banner: "/images/banners/features.png",
    },
    features: {
      title: "Unlock your platforms potential",
      cards: [
        {
          title: "Wallet accounts",
          subtitle: "Set up accounts instantly",
          text:
            "You can create individual accounts instantly with FinBlocks. These accounts are called virtual wallets. Each wallet is owned by either a natural user or a legal entity, and have their own sort code and account number. The wallet balance and transactions are tracked on a tamper-proof, transparent ledger. This ledger is backed up in real time; your client balances are accurate second by second.",
          icon: "/images/icons/wallet-accounts.png",
        },
        {
          title: "Deposits",
          subtitle: "Add funds securely ",
          text:
            "Depositing funds is also known as ‘pay-ins’. Your users can add a deposit into a wallet via their wallet sort code and account number, unique to each user, or by using an identification reference. Pay-ins can be made into the account through the users debit card, or direct debit as an alternative payment method. Payments are cleared into users wallets instantly.",
          icon: "/images/icons/deposits.png",
        },
        {
          title: "Withdrawals",
          subtitle: "Withdraw funds automatically",
          text:
            "Withdrawals of funds from wallets, also called ‘pay-outs’, can be made automatically via BACS transfer. After a user has requested a withdrawal, the funds are sent automatically from the wallet to the destination bank account — securely, instantly and most countries around the world.",
          icon: "/images/icons/withdrawals.png",
        },
        {
          title: "Transfers",
          subtitle: "Manage your wallets effectively",
          text:
            "Your system can instruct the instant transfer of funds between different wallets. Wallet to wallet transfers are recorded on the secure ledger, assuring all wallet balances are accurate.",
          icon: "/images/icons/transfers.png",
        },
        {
          title: "User Identification",
          subtitle: "Know your customers",
          text:
            "Knowing your users is a necessary regulatory obligation. Thorough identification and background checks are conducted for your users. These checks are facilitated using government issued identification documents and additional personal information about each user, such as their name and date of birth.",
          icon: "/images/icons/user-identification.png",
        },
        {
          title: "Anti Money Laundering",
          subtitle: "Be compliant with your obligations",
          text:
            "Your regulatory obligations for anti money laundering are covered when you use FinBlocks. We run extensive inhouse anti money laundering monitoring, and also conduct enhanced compliance with the support of additional third-party systems to minimise your business’ risk of breaching your regulatory obligations.",
          icon: "/images/icons/anti-money-laundering.png",
        },
        {
          title: "Monitoring",
          subtitle: " Reduce risk across your business",
          text:
            "The FinBlocks team will monitor your platform transactions to detect any transactions which could signal criminal or non-compliant activity taking place on your platform. We use a combination of automated system monitoring and human oversight. To help reduce risk across your business, your operational team also has access to these features. ",
          icon: "/images/icons/monitoring.png",
        },
      ],
    },
  },
  compliancePage: {
    header: {
      title: "Compliance, innovation and security are at our core",
      text1:
        "World class regulatory compliance sits at the heart of the FinBlocks platform.",
      text2:
        "The Financial Conduct Authority, or FCA, has a robust set of rules and guidelines to ensure that the UK Fintech sector operates in the interest of the country, and the individual users of Fintech platforms. There are appropriate and specific regulatory regimes which are fit for purpose in most jurisdictions where Fintech regulations have been embraced.",
      banner: "/images/banners/compliance.png",
    },
    complianceCards: {
      title: "Delivering you fit for purpose compliance",
      cards: [
        {
          title: "Giving you peace of mind",
          text:
            "We are a fully-regulated, FCA licensed platform, and adhere to the guidelines set out by the regulator. We will always strive for the highest level of compliance — you can do business with us knowing that we take our regulatory obligations seriously.",
          icon: "/images/icons/peace-of-mind.png",
        },
        {
          title: "Always stay compliant",
          text:
            "<p>All FinBlocks services function within the guidelines set out by the regulator and other industry experts, as part of our ongoing dedication to regulation and compliance.</p><p>We’ll always be compliant, and ensure that your business will be too.</p>",
          icon: "/images/icons/stay-compliant.png",
        },
        {
          title: "Focus on your business",
          text:
            "<p>There’s no need to allocate time and resources on regulation: we’ve done that for you.</p><p>We have the necessary regulations in place for you to focus on your delivering your proposition and serving your customers.</p>",
          icon: "/images/icons/focus-on-your-business.png",
        },
        {
          title: "Get to market quickly",
          text:
            "<p>Partnering with FinBlocks allows new businesses to speed up their time to market.</p><p>We manage the backend services for you, while you concentrate on developing your business. Our platform is  agile and highly flexible, and works across a number of use cases.</p>",
          icon: "/images/icons/get-to-market-quickly.png",
        },
        {
          title: "Real time compliance audits",
          text:
            "FinBlocks is audited on a regular basis, confirming we are in line with our regulatory obligations.  Our team undergo rigorous compliance training — nothing is ever overlooked from a regulatory perspective. Along with the support of our compliance partners, the experienced and qualified FinBlocks team always covers your needs.",
          icon: "/images/icons/audits.png",
        },
        {
          title: "Upholding our technical obligations",
          text:
            "Our software framework undergoes regular code reviews and is built and tested using best practice methodologies. We undergo regular performance and penetration security testing to protect your system and data.",
          icon: "/images/icons/technical-obligations.png",
        },
      ],
    },
  },
  technologyPage: {
    header: {
      title:
        "Built by world class engineers, maintaining the highest standards",
      text1:
        "FinBlocks is dedicated to the ongoing development of immaculate technology products, driven by forward thinking engineering design.",
      text2:
        "The platform architecture is designed to allow your team to integrate quickly, securely and seamlessly.",
      banner: "/images/banners/technology.png",
    },
    technology: {
      title: "Turbo charge your business",
      content:
        "<p>Developing payment software is expensive and time-consuming for you — and unnecessary. FinBlocks lightens your load, and reduces the amount of time your team spends integrating systems so they can focus on innovating.</p><p>Our dedicated onboarding team will make migrating from your existing legacy system or external provider easy. When integrated, your team has full access to our easy to use dashboard — customised to suit your business needs — where they can manage all operational processes.</p><p>We strive to provide a better service and a higher quality technology platform than any other competitor in the marketplace.</p>",
    },
  },
  companyPage: {
    header: {
      title: "Building a more transparent, secure and automated world",
      text1:
        "FinBlocks was founded to serve a fast-moving financial technology sector.",
      text2:
        "<p>Your success is our success.</p><p> Transparency, innovation and security are our values and we focus on delivering automated solutions that radically improve and future proof your businesses operations. It was clear there is a gap in the market because other providers are underperforming, not competitive and overcharging their customers. We pride ourselves in being market leaders and aim to deliver the highest standards.</p>",
      banner: "/images/banners/company.png",
    },
    story: {
      title: "Our story",
      content:
        "<p>We were founded through a clear insight that the financial services sector was radically shifting and incumbent software providers were clearly underperforming. It was clear that there was a need for a UK based provider for a secure and compliant SaaS provider of banking services. Our founding team we’re looking at international expansion and it was clear that there was an opportunity to deliver a global standard for this type of SaaS system. As blockchain and crypto currencies become more prevalent it is critical to embrace these technologies in a compliant way.</p><p>We believe in empowering you through technology so that you can focus on your business and achieve your goals.<p>",
    },
  },
};
export default data;
