import React from "react";
import Banner from "../components/Banner/Banner";
import CardsSection from "../components/CardsSection/CardsSection";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import IndustryNews from "../components/IndustryNews/IndustryNews";
import SimpleSection from "../components/SimpleSection/SimpleSection";

const BankingPage = ({ data }) => {
  const { header, whyUs, benefits } = data;
  return (
    <main>
      <div className="text-center">
        <Banner data={header} />
        <SimpleSection data={whyUs} />
        <GetInTouch />
        <CardsSection data={benefits} />
        <IndustryNews />
      </div>
    </main>
  );
};

export default BankingPage;
